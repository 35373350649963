.flexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 120px;
  background: linear-gradient(#2bab6f 0%, #0b523a 100%);
}

.aboutUsText {
  flex: 1;
  margin-right: 20px;
  margin-left: 150px;
  margin-bottom: 80px;
}

.imageAndDictionaryWrapper {
  flex: 1;
  background-color: #ffffff;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 0%);
}

.imageAndDictionary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 150px;
}

.dictionaryDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-family: 'PT Serif';
  gap: 10px;
  padding-left: 120px;
}

.dictionaryDescription p {
  font-size: 20px;
  margin: 0;
  color: #000000;
}

@media (max-width: 1400px) {
  .flexContainer {
    padding-top: 100px;
  }

  .aboutUsText {
    margin-left: 60px;
  }

  .imageAndDictionary {
    margin-right: 60px;
  }
}

@media (max-width: 768px) {
  .flexContainer {
    flex-direction: column;
  }

  .aboutUsText {
    margin-left: 20px;
  }

  .imageAndDictionary {
    margin-right: 20px;
  }

  .dictionaryDescription {
    padding: 20px;
  }

  .aboutText,
  .imageAndDictionary {
    margin-right: 0;
    align-items: center;
  }

  .imageAndDictionaryWrapper {
    clip-path: polygon(0% 3%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.imageWidth {
  width: 250px;
  height: auto;
  margin: 20;
}

.aboutUsText h2 {
  font-size: 54px;
  color: #ffffff;
}

.aboutUsText p {
  font-size: 18px;
  color: #ffffff;
}
